import ContrastColor from "contrast-color";

const cc = new ContrastColor();

function getAnnotationGlyphColor(tracker) {
    const {
        color
    } = tracker;
    if(!color) {
        return null;
    }

    return cc.contrastColor({ bgColor: color });
}

export default getAnnotationGlyphColor;
