import audi from "../img/marker-glyphs/audi.svg";
import man from "../img/marker-glyphs/man.svg";
import mercedes from "../img/marker-glyphs/mercedes.svg";
import opel from "../img/marker-glyphs/opel.svg";
import toyota from "../img/marker-glyphs/toyota.svg";
import volkswagen from "../img/marker-glyphs/volkswagen.svg";

const glyphs = {
    audi,
    man,
    mercedes,
    opel,
    toyota,
    volkswagen
}

function getAnnotationGlyphImage(tracker) {
    const {
        name
    } = tracker;
    if(!name || !name.toLowerCase) {
        return null;
    }

    for(const [id, image] of Object.entries(glyphs)) {
        if(name.toLowerCase().includes(id)) {
            return image;
        }
    }
    return null;
}

export default getAnnotationGlyphImage;
